export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';

export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAIL = 'CREATE_DOCUMENT_FAIL';
export const CREATE_DOCUMENT_RESET = 'CREATE_DOCUMENT_RESET';

export const SIGN_DOCUMENT_START = 'SIGN_DOCUMENT_START';
export const SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_FAIL = 'SIGN_DOCUMENT_FAIL';
export const SIGN_DOCUMENT_RESET = 'SIGN_DOCUMENT_RESET';

export const ARCHIVE_DOCUMENT_START = 'ARCHIVE_DOCUMENT_START';
export const ARCHIVE_DOCUMENT_SUCCESS = 'ARCHIVE_DOCUMENT_SUCCESS';
export const ARCHIVE_DOCUMENT_FAIL = 'ARCHIVE_DOCUMENT_FAIL';
export const ARCHIVE_DOCUMENT_RESET = 'ARCHIVE_DOCUMENT_RESET';

export const GET_DOCUMENT_STATUS_START = 'GET_DOCUMENT_STATUS_START';
export const GET_DOCUMENT_STATUS_SUCCESS = 'GET_DOCUMENT_STATUS_SUCCESS';
export const GET_DOCUMENT_STATUS_FAIL = 'GET_DOCUMENT_STATUS_FAIL';
export const GET_DOCUMENT_STATUS_RESET = 'GET_DOCUMENT_STATUS_RESET';

export const BULK_DOCUMENT_START = 'BULK_DOCUMENT_START';
export const BULK_DOCUMENT_SUCCESS = 'BULK_DOCUMENT_SUCCESS';
export const BULK_DOCUMENT_FAIL = 'BULK_DOCUMENT_FAIL';
export const BULK_DOCUMENT_RESET = 'BULK_DOCUMENT_RESET';

export const RESET_DOCUMENT_STORE = 'RESET_DOCUMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type DocumentActionTypes =
  | typeof FETCH_DOCUMENTS_START
  | typeof FETCH_DOCUMENTS_SUCCESS
  | typeof FETCH_DOCUMENTS_FAIL
  | typeof RESET_DOCUMENT_STORE
  | typeof CREATE_DOCUMENT_START
  | typeof CREATE_DOCUMENT_SUCCESS
  | typeof CREATE_DOCUMENT_FAIL
  | typeof CREATE_DOCUMENT_RESET
  | typeof SIGN_DOCUMENT_START
  | typeof SIGN_DOCUMENT_SUCCESS
  | typeof SIGN_DOCUMENT_FAIL
  | typeof SIGN_DOCUMENT_RESET
  | typeof ARCHIVE_DOCUMENT_START
  | typeof ARCHIVE_DOCUMENT_SUCCESS
  | typeof ARCHIVE_DOCUMENT_FAIL
  | typeof ARCHIVE_DOCUMENT_RESET
  | typeof GET_DOCUMENT_STATUS_START
  | typeof GET_DOCUMENT_STATUS_SUCCESS
  | typeof GET_DOCUMENT_STATUS_FAIL
  | typeof GET_DOCUMENT_STATUS_RESET
  | typeof BULK_DOCUMENT_START
  | typeof BULK_DOCUMENT_SUCCESS
  | typeof BULK_DOCUMENT_FAIL
  | typeof BULK_DOCUMENT_RESET
  | typeof LOGOUT;
