export const routes = {
  homepage: '/admin',
  login: '/login',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  documents: {
    list: '/admin/documents',
  },
  translations: '/admin/translations',
};
