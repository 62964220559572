import * as actionTypes from './actionTypes';
import { DocumentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Document } from '../../domain/Document';
import { ListResults } from '../../common/List/List';

export type DocumentStateType = {
  documentsList: ListResults<Document> | null;
  documentsLoading: boolean;
  documentsError: HttpError;
  documentsListUpdateNeeded: boolean;
  documentCreateLoading: boolean;
  documentCreateError: HttpError;
  documentCreateSuccess: boolean;
  createdDocument: Document | null;
  documentSignLoading: boolean;
  documentSignError: HttpError;
  documentSignSuccess: boolean;
  signDocument: Document | null;
  documentArchiveLoading: boolean;
  documentArchiveError: HttpError;
  documentArchiveSuccess: boolean;
  archiveDocument: Document | null;
  documentStatusLoading: boolean;
  documentStatusError: HttpError;
  bulkDocumentLoading: boolean;
  bulkDocumentError: HttpError;
  bulkDocumentSuccess: boolean;
};

export type DocumentActionType = DocumentStateType & {
  type: DocumentActionTypes;
};

export const initialState: DocumentStateType = {
  documentsList: null,
  documentsLoading: true,
  documentsError: null,
  documentsListUpdateNeeded: false,
  documentCreateLoading: false,
  documentCreateError: null,
  documentCreateSuccess: false,
  createdDocument: null,
  documentSignLoading: false,
  documentSignError: null,
  documentSignSuccess: false,
  signDocument: null,
  documentArchiveLoading: false,
  documentArchiveError: null,
  documentArchiveSuccess: false,
  archiveDocument: null,
  documentStatusLoading: false,
  documentStatusError: null,
  bulkDocumentLoading: false,
  bulkDocumentError: null,
  bulkDocumentSuccess: false,
};

const fetchDocumentsStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentsLoading: true,
});

const fetchDocumentsSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentsList: action.documentsList,
  documentsLoading: false,
  documentsError: null,
  documentsListUpdateNeeded: false,
  documentArchiveSuccess: false,
  archiveDocument: null,
});

const fetchDocumentsFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentsError: action.documentsError,
  documentsLoading: false,
});

const createDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentCreateLoading: true,
  documentCreateSuccess: false,
});

const createDocumentSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentCreateLoading: false,
  documentCreateError: null,
  documentCreateSuccess: true,
  createdDocument: action.createdDocument,
  documentsListUpdateNeeded: true,
});

const createDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentCreateLoading: false,
  documentCreateError: action.documentCreateError,
  documentsListUpdateNeeded: true,
});

const signDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentSignLoading: true,
});

const signDocumentSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentSignLoading: false,
  documentSignError: null,
  documentSignSuccess: true,
  documentsListUpdateNeeded: true,
  signDocument: action.signDocument,
});

const signDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentSignLoading: false,
  documentSignError: action.documentSignError,
  documentsListUpdateNeeded: true,
});

const archiveDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentArchiveLoading: true,
});

const archiveDocumentSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentArchiveLoading: false,
  documentArchiveError: null,
  documentArchiveSuccess: true,
  documentsListUpdateNeeded: true,
  archiveDocument: action.archiveDocument,
});

const archiveDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentArchiveLoading: false,
  documentArchiveError: action.documentArchiveError,
  documentsListUpdateNeeded: true,
});

const getDocumentStatusStart = (
  state: DocumentStateType,
): DocumentStateType => ({
  ...state,
  documentStatusLoading: true,
});

const getDocumentStatusSuccess = (
  state: DocumentStateType,
): DocumentStateType => ({
  ...state,
  documentStatusLoading: false,
  documentStatusError: null,
  documentsListUpdateNeeded: true,
});

const getDocumentStatusFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentStatusLoading: false,
  documentStatusError: action.documentStatusError,
  documentsListUpdateNeeded: true,
});

const bulkDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  bulkDocumentLoading: true,
});

const bulkDocumentSuccess = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  bulkDocumentLoading: false,
  bulkDocumentError: null,
  bulkDocumentSuccess: true,
  documentsListUpdateNeeded: true,
});

const bulkDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  bulkDocumentLoading: false,
  bulkDocumentError: action.bulkDocumentError,
  documentsListUpdateNeeded: true,
});

const resetDocumentStore = (): DocumentStateType => ({
  ...initialState,
});

const resetCreatedDocument = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  createdDocument: null,
  documentCreateSuccess: false,
});

const resetSignDocument = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  signDocument: null,
  documentSignSuccess: false,
});

const resetArchiveDocument = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  archiveDocument: null,
  documentArchiveSuccess: false,
});

const resetGetDocumentStatus = (
  state: DocumentStateType,
): DocumentStateType => ({
  ...state,
});

const reducer = (state = initialState, action: DocumentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENTS_START:
      return fetchDocumentsStart(state);
    case actionTypes.FETCH_DOCUMENTS_SUCCESS:
      return fetchDocumentsSuccess(state, action);
    case actionTypes.FETCH_DOCUMENTS_FAIL:
      return fetchDocumentsFail(state, action);
    case actionTypes.CREATE_DOCUMENT_START:
      return createDocumentStart(state);
    case actionTypes.CREATE_DOCUMENT_SUCCESS:
      return createDocumentSuccess(state, action);
    case actionTypes.CREATE_DOCUMENT_FAIL:
      return createDocumentFail(state, action);
    case actionTypes.SIGN_DOCUMENT_START:
      return signDocumentStart(state);
    case actionTypes.SIGN_DOCUMENT_SUCCESS:
      return signDocumentSuccess(state, action);
    case actionTypes.SIGN_DOCUMENT_FAIL:
      return signDocumentFail(state, action);
    case actionTypes.ARCHIVE_DOCUMENT_START:
      return archiveDocumentStart(state);
    case actionTypes.ARCHIVE_DOCUMENT_SUCCESS:
      return archiveDocumentSuccess(state, action);
    case actionTypes.ARCHIVE_DOCUMENT_FAIL:
      return archiveDocumentFail(state, action);
    case actionTypes.GET_DOCUMENT_STATUS_START:
      return getDocumentStatusStart(state);
    case actionTypes.GET_DOCUMENT_STATUS_SUCCESS:
      return getDocumentStatusSuccess(state);
    case actionTypes.GET_DOCUMENT_STATUS_FAIL:
      return getDocumentStatusFail(state, action);
    case actionTypes.BULK_DOCUMENT_START:
      return bulkDocumentStart(state);
    case actionTypes.BULK_DOCUMENT_SUCCESS:
      return bulkDocumentSuccess(state);
    case actionTypes.BULK_DOCUMENT_FAIL:
      return bulkDocumentFail(state, action);
    case actionTypes.RESET_DOCUMENT_STORE:
      return resetDocumentStore();
    case actionTypes.CREATE_DOCUMENT_RESET:
      return resetCreatedDocument(state);
    case actionTypes.SIGN_DOCUMENT_RESET:
      return resetSignDocument(state);
    case actionTypes.ARCHIVE_DOCUMENT_RESET:
      return resetArchiveDocument(state);
    case actionTypes.GET_DOCUMENT_STATUS_RESET:
      return resetGetDocumentStatus(state);
    case actionTypes.LOGOUT:
      return resetDocumentStore();
    default:
      return state;
  }
};

export default reducer;
