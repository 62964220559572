import axios from '../../config/Axios/axios-instance';
import {
  loginGoogleStart,
  loginGoogleSuccess,
  loginGoogleFail,
  logout,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFail,
} from './actions';
import { Dispatch } from 'redux';

const API_URL = '/auth';

export type LoginGoogleRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  token: string;
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios
      .post(`${API_URL}/google`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(loginGoogleSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err?.response?.data?.message));
      });
  };

export const refreshToken = () => (dispatch: Dispatch) => {
  dispatch(refreshTokenStart());
  return axios
    .post(`${API_URL}/refresh`)
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(refreshTokenSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(refreshTokenFail(err?.response?.data?.message));
    });
};

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(refreshTokenSuccess(token)) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(logout());
};
